<script setup>
import AppHero from './structure/AppHero.vue'
</script>

<template>
  <AppHero></AppHero>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
@import './styles/main.scss';
@import './styles/variables.scss';
@import './styles/buttons.scss';

body {
  margin: 0;
  //background-color: $background_main;
}
html {
  background-color: $background_fade_2 !important;
  background: linear-gradient(180deg, lighten($background_main, 7%) 0%, $background_fade_3 80%);
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: $background_main;
}
::-webkit-scrollbar-thumb {
  background: $text_note;
  border-radius: 4px;
}
</style>
