<script setup>
  import SquarePixel from '@/components/parts/SquarePixel.vue'
</script>

<template>
  <div class="hk-effect-bck-container">
    <SquarePixel v-for="i in particles" :key="i" :index="i" :max="particles" :style="{width: size + 'px', height: size + 'px'}"/>    
  </div>
</template>

<script>
export default {
  props: {
    particles: {
      typeof: Number,
      required: true,
    },
  },
  data(){
    return {
      size: 0,
    }
  },
  methods: {
    calcSize() {
      if (this.particles > 0) {
        this.size = Math.sqrt((window.innerHeight * window.innerWidth) / this.particles)
      } else {
        this.size = 0
      }
    },
  },
  mounted() {
    this.calcSize()
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 600) {
        return
      }
      this.calcSize()
    })
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';
.hk-effect-bck-container {
  height: inherit;
  width: inherit;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
