<template>
  <div class="hk-eff-box-holder" :style="{animationDuration: rotate+'s'}">
    <div class="hk-eff-a" :style="{animationDuration: x_interval+'s', borderColor: 'rgb(73,'+255*hue+',255)'}"></div>
    <div class="hk-eff-b" :style="{animationDuration: y_interval+'s', borderColor: 'rgb(73,'+255*hue+',255)'}"></div>
  </div>
</template>

<script>
export default {
  props:{
    index: {
      typeof: Number,
      required: true
    },
    max: {
      typeof: Number,
      required: true
    },
  },
  data() {
    return {
      x_interval: ((Math.random() * 10 + 4 )),
      y_interval: ((Math.random() * 10 + 4)),
      rotate: ((Math.random() * 20 + 12)),
      hue: 0.5
    }
  },
  mounted() {
    this.hue = this.index/this.max
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/variables';

  $border_width: 35px;
  $border_color: white;
  $drop_color: white;

  .hk-eff-box-holder {
    position: relative;
    rotate: 46deg;
    animation: rotate360 5s linear infinite;
    div {
      position: absolute;
      width: inherit;
      height: inherit;
      border-radius: 48%;
    }
  }
  .hk-eff-a {
    border-top: $border_width solid $border_color;
    animation: effect 5s linear infinite;
  }
  .hk-eff-b {
    border-bottom: $border_width solid $border_color;
    animation: effect 5s linear infinite;
  }
  @keyframes effect {
    0% {opacity: 0.0;}
    20% {opacity: 0.4;}
    40% {opacity: 0.0;}
    100% {opacity: 0.0;}
  }
  @keyframes rotate360 {
    0%{rotate: 0deg;}
    100%{rotate: 360deg;}
  }
</style>
