<script setup>
import EffectBackground from '../components/EffectBackground.vue'
</script>

<template>
  <section>
    <div class="hk-hero-eff">
      <EffectBackground :particles="21"/>
    </div>
    <div class="hk-hero-content">
      
      <div class="hk-hero-main-container">
        <div class="hk-hero-content-holder">
          <img src="../assets/hk-lg-big-light-badge-outl.png" alt="HKubo logo">
          <div class="hk-hero-content-header">
            <h2>web</h2>
            <h2>.</h2>
            <h2>software</h2>
            <h2>.</h2>
            <h2>IoT</h2>
          </div>
          <div>
            <h3>HKubo</h3>
          </div>
        </div>
        <!--<div class="hk-hero-effect-div"></div>-->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    scrollDown() {
      window.scrollBy({top: (window.innerHeight*0.9), behavior: "smooth"})
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

section {
  position: relative;
}
.hk-hero-content {
  position: relative;
  z-index: 10;

  > div {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hk-hero-content-fade {
    position: relative;
    height: 20vh;
    background-color: $background_fade_2;
    box-shadow: 0 0 50px 80px $background_fade_2;
    button {
      margin-top: -25vh;
    }
  }

  .hk-hero-main-container {
    position: relative;
    overflow: hidden;
    .hk-hero-content-holder {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2000;
      margin-bottom: 3rem;

      h3 {
        font-family: Manrope;
        font-weight: 100;
        font-size: 15px;
        color: #888;
        margin-top: 2.4rem;
      }
    }
    .hk-hero-effect-div {
      position: absolute;
      width: calc(min(80vw, 400px) + 100px);
      height: calc(min(80vw, 400px) + 100px);
      border-radius: 50%;
      filter: blur(10px);

      border-top: 40px solid #406789;
      animation: rotation2 10s infinite cubic-bezier(0.53, 0.55, 0.81, 0.41);
    }
  }

  img {
    width: min(60vw, 90px);
    padding-bottom: 2.5rem;
    margin-left: 0.7rem;
  }

  .hk-hero-content-header {
    h2 {
      color: $text_main;
      mix-blend-mode: difference;
      
      font-family: Syne;
      font-weight: 900;
      font-size: 50px;
      letter-spacing: 2px;
    }
    display: flex;
    justify-content: center;
    * {
      margin: 0 0.5rem;
    }
  }
}

@media only screen and (max-width: 599.99px) {
  .hk-hero-content-header h2{
    font-size: 1.5rem !important;
  }
  img {
    width: min(50vw, 60px) !important;
    padding-bottom: 2.5rem !important;
  }
}

.hk-hero-eff {
  position: absolute;
  z-index: 0;
  filter: blur(40px);
  overflow: hidden;
  width: 100%;
  height: 100%;
}

@keyframes rotation2 {
  0% {opacity: 0; rotate: 180deg;}
  20% {opacity: 1;}
  29% {opacity: 1;}
  60% {opacity: 0;}
  100% {opacity: 0; rotate: 540deg;}
}
</style>
